import React from 'react';
import NavBar from '../Navbar';
import './index.css'
function Blog() {
  return (
    <>
      <NavBar/>
      <div className='Blog'>
        <h1 className='main-heading'>Blog page is under construction.</h1>
      </div>
    </>
    
  );
}

export default Blog;
