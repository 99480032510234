import React from 'react';
import NavBar from '../Navbar';
import './index.css'
function About() {
  return (
    <>
      <NavBar/>
      <div className='About'>
        <h1 className='main-heading'>About Me</h1>
        <p className='paragraph'>Welcome to our website. Here's some information about Me:</p>
        <p className='sub-paragraph'></p>
      </div>
    </>
    
  );
}

export default About;
